import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/utils/axios';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const active = ref('profile');
    const permission = ref('member');
    watch(active, newVal => {
      router.push({
        path: `/home/${newVal}`
      });
    });
    onMounted(() => {
      const path = router.currentRoute.value.path;
      active.value = path.split('/')[2];
      axios.get('/getPermission').then(response => {
        if (response.code) {
          permission.value = response.permission;
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_router_view = _resolveComponent("router-view");
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        title: "内镜管理系统",
        fixed: true,
        placeholder: true
      }), _createVNode(_component_router_view), _createVNode(_component_van_tabbar, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event),
        placeholder: true
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
          name: "fill",
          icon: "add-o"
        }, {
          default: _withCtx(() => [_createTextVNode("填写")]),
          _: 1
        }), _createVNode(_component_van_tabbar_item, {
          name: "query",
          icon: "search"
        }, {
          default: _withCtx(() => [_createTextVNode("查询")]),
          _: 1
        }), _createVNode(_component_van_tabbar_item, {
          name: "statistic",
          icon: "orders-o"
        }, {
          default: _withCtx(() => [_createTextVNode("统计")]),
          _: 1
        }), _createVNode(_component_van_tabbar_item, {
          name: "verify",
          icon: "flag-o"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(permission.value == 'member' ? "待审核" : "审核"), 1)]),
          _: 1
        }), permission.value != 'member' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createVNode(_component_van_tabbar_item, {
          name: "batch",
          icon: "logistics"
        }, {
          default: _withCtx(() => [_createTextVNode("批量")]),
          _: 1
        }), _createVNode(_component_van_tabbar_item, {
          name: "users",
          icon: "friends-o"
        }, {
          default: _withCtx(() => [_createTextVNode("用户")]),
          _: 1
        })], 64)) : _createCommentVNode("", true), _createVNode(_component_van_tabbar_item, {
          name: "profile",
          icon: "user-o"
        }, {
          default: _withCtx(() => [_createTextVNode("个人")]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};