import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-52e55c12"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "margin": "40px 20px 60px"
  }
};
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import axios from '@/utils/axios';
import { showDialog } from 'vant';
// import 'vant/es/dialog/style'

export default {
  __name: 'RegisterView',
  setup(__props) {
    const router = useRouter();
    const form = reactive({
      loginName: '',
      nickname: '',
      password: '',
      password2: '',
      email: ''
    });
    const formatter = value => value.replace(/ /g, '');
    function onSubmit() {
      const postData = JSON.parse(JSON.stringify(form));
      axios.post('/register', postData).then(response => {
        if (response.code) {
          showDialog({
            message: response.msg
          }).then(() => {
            router.push({
              path: '/login'
            });
          });
        } else {
          showDialog({
            message: response.msg
          });
        }
      });
    }
    function goBack() {
      history.back();
    }
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        title: "注册账号",
        "left-text": "返回",
        "left-arrow": "",
        onClickLeft: goBack,
        fixed: ""
      }), _createVNode(_component_van_image, {
        round: "",
        width: "150px",
        height: "150px",
        style: {
          "margin": "90px auto 60px"
        },
        src: require('@/assets/images/hospital.png'),
        class: "icon"
      }, null, 8, ["src"]), _createVNode(_component_van_form, {
        onSubmit: onSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            modelValue: form.loginName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.loginName = $event),
            label: "用户名",
            placeholder: "请输入您的用户名",
            rules: [{
              required: true,
              message: '请填写用户名'
            }],
            formatter: formatter
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: form.nickname,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.nickname = $event),
            label: "真实姓名",
            placeholder: "请输入您的真实姓名",
            rules: [{
              required: true,
              message: '请填写真实姓名'
            }],
            formatter: formatter
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: form.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.password = $event),
            type: "password",
            label: "密码",
            placeholder: "请输入您的密码",
            rules: [{
              required: true,
              message: '请填写密码'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: form.password2,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.password2 = $event),
            type: "password",
            label: "再次输入密码",
            placeholder: "请再次输入您的密码",
            rules: [{
              required: true,
              message: '请再次填写密码'
            }]
          }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
            modelValue: form.email,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form.email = $event),
            label: "电子邮箱",
            placeholder: "请输入您的电子邮箱",
            rules: [{
              required: true,
              message: '请填写电子邮箱'
            }],
            formatter: formatter
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 注册 ")]),
          _: 1
        })])]),
        _: 1
      })], 64);
    };
  }
};