import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction
  ? ''
  : 'http://localhost:8080/api';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以进行一些操作，例如添加 token 等
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 对响应数据进行处理，例如解密等
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
)

export default axiosInstance