import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
import ManageUsers from '@/components/Users/ManageUsers';
import AddUsers from '@/components/Users/AddUsers';
export default {
  __name: 'UsersView',
  setup(__props) {
    const active = ref("");
    const list = ref([]);
    const updateList = newValue => {
      list.value = newValue;
    };
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createBlock(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        swipeable: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: "管理用户",
          name: "manageUsers"
        }, {
          default: _withCtx(() => [_createVNode(_unref(ManageUsers), {
            list: list.value,
            onUpdateList: updateList
          }, null, 8, ["list"])]),
          _: 1
        }), _createVNode(_component_van_tab, {
          title: "添加用户",
          name: "addUsers"
        }, {
          default: _withCtx(() => [_createVNode(_unref(AddUsers), {
            onUpdateList: updateList
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["active"]);
    };
  }
};