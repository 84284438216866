import axios from '@/utils/axios'

async function isAuth() {
    try {
      const response = await axios.get('/isAuth')
      if (response.code === 1) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.error(error)
      return false
    }
  }

export default isAuth
