import { createRouter, createWebHashHistory } from 'vue-router'
import isAuth from '@/utils/isAuth'

import HomeView from '@/views/HomeView'
import LoginView from '@/views/LoginView'
import ForgetView from '@/views/ForgetView'
import RegisterView from '@/views/RegisterView'
import BatchView from '@/views/HomeView/BatchView'
import FillView from '@/views/HomeView/FillView'
import ProfileView from '@/views/HomeView/ProfileView'
import QueryView from '@/views/HomeView/QueryView'
import StatisticView from '@/views/HomeView/StatisticView'
import UsersView from '@/views/HomeView/UsersView'
import VerifyView from '@/views/HomeView/VerifyView'

const routes = [
    {
        path: '/',
        redirect: '/home/profile'
    },
    {
        path: '/home',
        component: HomeView,
        children: [
            {
                path: 'batch',
                component: BatchView
            },
            {
                path: 'fill',
                component: FillView
            },
            {
                path: 'profile',
                component: ProfileView
            },
            {
                path: 'query',
                component: QueryView
            },
            {
                path: 'statistic',
                component: StatisticView
            },
            {
                path: 'users',
                component: UsersView
            },
            {
                path: 'verify',
                component: VerifyView
            }
        ]
    },
    {
        path: '/login',
        component: LoginView,
        meta: {
            authNotRequire: true
        }
    },
    {
        path: '/forget',
        component: ForgetView,
        meta: {
            authNotRequire: true
        }
    },
    {
        path: '/register',
        component: RegisterView,
        meta: {
            authNotRequire: true
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    const authStatus = await isAuth()
    if (to.meta.authNotRequire) {
        if (authStatus) {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (authStatus) {
            next()
        } else {
            next({ path: '/login' })
        }
    }
})

export default router
